
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

const toogleKeyInSchedule = (scheduleKey, schedule) => {
  const newSchedule = {...schedule}
  const scheduleValue = schedule[scheduleKey]
  if (typeof scheduleValue === 'boolean') {
    newSchedule[scheduleKey] = !scheduleValue
  }
  if (newSchedule[scheduleKey] === true && scheduleKey !== 'level') {
    newSchedule['level'] = true
  }
  return newSchedule

}


const clearCurrentSchedule = (schedule) => {
  let obj = {...schedule}
  const fieldsToReset = ['morning', 'intermedio', 'afternoon', 'evening', 'double_schedule', 'time_extension'];

  Object.keys(schedule).forEach(key => {
    if (fieldsToReset.includes(key)) {
      obj[key] = false
    }
  })
  return obj
}

const updateSchedules = (schedules, index, newSchedule) => {
    return schedules.map((el, i) => {
      if (index === i) return newSchedule;
      return el
    } , [])
}

const  Schedules = React.memo(({styles, schedules, setSchedules}) => {
  return (
    <>
    <h2>Etapas Educativas</h2>
            <div className="input-block">
              <table className="table_schedules">
                <thead>
                  <tr>
                  <th className={styles.ages}>Edad</th>
                  <th className={styles.etapa}>Etapa </th>
                  <th>Nivel</th>
                  <th>Matutino</th>
                  <th>Vespertino</th>
                  <th>Nocturno</th>
                  <th>Doble horario</th>
                  <th>Hora adicional</th>
                  </tr>
                </thead>

                <tbody>
                  {schedules.map((schedule, index) => (
                    <tr key={schedule.ages}>
                      <td className={styles.ages}>{schedule.ages}</td>
                      <td className={styles.etapa}>{schedule.step}</td>                      
                      <td>
                        <Checkbox
                          color="primary"
                          onChange={(e) => {
                            const toogledSchedule = toogleKeyInSchedule('level', schedule)
                            if (toogledSchedule['level'] === false) {
                              const noValueSchedule = clearCurrentSchedule(toogledSchedule)
                              const updatedSchedules = updateSchedules(schedules, index, noValueSchedule)
                              setSchedules(updatedSchedules)
                            } else {
                              const updatedSchedules = updateSchedules(schedules, index, toogledSchedule)
                              setSchedules(updatedSchedules)
                            }


                          }}
                          checked={schedule.level}
                        />
                      </td>

                      <td>
                        <Checkbox
                          color="primary"
                          onChange={() => 
                            {
                              const toogledSchedule = toogleKeyInSchedule('morning', schedule)
                              const updatedSchedules = updateSchedules(schedules, index, toogledSchedule)
                              setSchedules(updatedSchedules)
                            }
                          }
                          checked={schedule.morning}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          onChange={() => 
                            {
                              const toogledSchedule = toogleKeyInSchedule('afternoon', schedule)
                              const updatedSchedules = updateSchedules(schedules, index, toogledSchedule)
                              setSchedules(updatedSchedules)
                            }
                          }
                          checked={schedule.afternoon}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          onChange={() => 
                            {
                              const toogledSchedule = toogleKeyInSchedule('evening', schedule)
                              const updatedSchedules = updateSchedules(schedules, index, toogledSchedule)
                              setSchedules(updatedSchedules)
                            }
                          }
                          checked={schedule.evening}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          onChange={() => 
                            {
                              const toogledSchedule = toogleKeyInSchedule('double_schedule', schedule)
                              const updatedSchedules = updateSchedules(schedules, index, toogledSchedule)
                              setSchedules(updatedSchedules)
                            }
                          }
                          checked={schedule.double_schedule}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          onChange={() => 
                            {
                              const toogledSchedule = toogleKeyInSchedule('time_extension', schedule)
                              const updatedSchedules = updateSchedules(schedules, index, toogledSchedule)
                              setSchedules(updatedSchedules)
                            }
                          }
                          checked={schedule.time_extension}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
    </>
  )
})

export default Schedules;

