import React from 'react'
import TextField from '@material-ui/core/TextField'

function InputText({
  className,
  onBlur = () => {},
  disabled,
  label,
  setValue,
  value,
  required,
  type,
  variant,
  error
  }) {
  return (
    <TextField
      type={type}
      required={required}
      disabled={disabled}
      label={label}
      onBlur={async (e) => await onBlur(e)}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      error={error}
      className={className}
      variant={variant || "filled"}
    />
  )
}

export default InputText

