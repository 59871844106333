import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';
import { validateNewPassword, renderCheckPasswordError } from '../../utils/validateNewPassword';
import Logo from '../../assets/Vacanted_LOGO_header.png';

function PasswordChange() {
  const history = useHistory();
  const { user, token } = useAuth();
  const [newPasswordData, setNewPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordCheck: '',
  });
  const [passwordError, setPasswordError] = useState({ msg: 'Las nuevas contraseñas no coinciden', error: false });

  function handleInputs({ target }) {
    const { name, value } = target;

    setNewPasswordData({ ...newPasswordData, [name]: value });
    setPasswordError({ ...passwordError, error: false });
  }

  function handleSubmit(event) {
    event.preventDefault();

    const { oldPassword, newPassword } = newPasswordData;
    if (!validateNewPassword(newPasswordData)) {
      setPasswordError({ ...passwordError, error: true });
    } else {
      const data = {
        email: user.email,
        password: oldPassword,
        newpassword: newPassword,
      };
  
      let config = {
        headers: {
          authorization: token,
        },
      };
  
      api
        .put('user', data, config)
        .then(() => {
          history.push('/dashboard');
        })
        .catch((e) => {
          alert(e.message);
        });
    }
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "90vh" }}
    >
      <form onSubmit={handleSubmit} className="login-register-form">
        <img alt="school logo" src={Logo}></img>
        <Link to="/dashboard">
          <FiArrowLeft size={26} color="rgba(0, 0, 0, 0.6" />
        </Link>
        <div className="input-fields">
          <input
            type="password"
            name="oldPassword"
            placeholder="Contraseña actual"
            onChange={handleInputs}
            required
          />
          <input
            type="password"
            name="newPassword"
            placeholder="Nueva contraseña"
            onChange={handleInputs}
            required
          />
          <input
            type="password"
            name="newPasswordCheck"
            placeholder="Reingresá la nueva contraseña"
            onChange={handleInputs}
            required
          />
          {passwordError.error && renderCheckPasswordError(passwordError.msg)}
          <button className="confirm-button" type="submit">
            CONFIRMAR
          </button>
        </div>
      </form>
    </Grid>
  );
}

export default PasswordChange;
