import axios from 'axios';
import fetch from 'unfetch';
import { getUrl } from '../urlConfig';

const api = axios.create({
  baseURL: getUrl('api'),
});

api.updateSchool = async (data, images, school, token) => {
  let config  = {
    headers: {
      authorization: token,
    }
  }

  let multipart = {
    headers: {
      authorization: token,
      'content-type': 'multipart/form-data'
    }
  }
  if (images.length !== 0) {
    const formData = new FormData();
    formData.append('filename', images[0]);
    await api
      .post(`/school/logo/${school}`, formData, multipart)
      .then((response) => { console.log(response) })
      .catch((err) => {  });
  }
  await api
  .put(`/school/schoolupdate/${school}`, data, config)
}

api.fetchWithToken = (url, token) => fetch(url, { headers: { authorization: token }, method: "GET" })
  .then(r => r.json())
  .catch(err => console.error(err))

export default api;
