import React from 'react';
import {
  DataGrid, GridToolbarContainer,
  GridToolbarDensitySelector, GridOverlay
} from '@material-ui/data-grid'
import { Button, TablePagination, LinearProgress } from '@material-ui/core'
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext'


const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}



const columns = [
  { field: 'id', headerName: 'ID', width: '120px', hide: true },

  { field: 'id_vacanted', headerName: 'Id Vacanted', flex: 1 },
  { field: 'name', headerName: 'Nombre institución', flex: 1 },
  {
    field: 'city', headerName: 'Ciudad', flex: 1,
    valueFormatter: (params) => {
      return params.row.city.name
    },
  },
  { field: 'address', headerName: 'Dirección', flex: 1 },
  {
    field: 'countryName', headerName: 'Editar', maxWidth: '230px',
    renderCell: (school) => (
      <Link to={`/ambassador_input/${school.id}`} style={{ textDecoration: 'none' }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
        >
          Ingresar
      </Button>
      </Link>
    )
  },
];

const getCurrentPagination = (pageIndex, maxRowsDisplayed) => {

  return [pageIndex * maxRowsDisplayed, (pageIndex + 1) * maxRowsDisplayed]
}


const DataTable = () => {
  const { setDataPagination, totalRows, dataPagination } = React.useContext(UserContext)
  const [initial, last] = getCurrentPagination(dataPagination.p, dataPagination.l)
  const { schools, isLoading } = React.useContext(UserContext)
  if (!schools) return <>...</>
  return (
    <div style={{ height: 480, width: '100%' }}>

      <DataGrid
        rows={schools}
        columns={columns}
        loading={isLoading}
        density="compact"
        paginationMode="server"
        disableColumnMenu
        disableMultipleColumnsSorting
        maxWidth={1000}
        components={
          {
            Pagination: () => <TablePagination
              backIconButtonProps={{
                onClick: () => setDataPagination(
                  {
                    ...dataPagination,
                    p: (dataPagination.p) - 1
                  }
                ),
                disabled: dataPagination.p <= 0

              }}
              nextIconButtonProps={{
                onClick: () => setDataPagination(
                  { ...dataPagination, p: (dataPagination.p) + 1 }
                ),
                disabled: last >= totalRows
              }}
              labelRowsPerPage={<small>Lineas por pagina</small>}
              onChangeRowsPerPage={(e) => setDataPagination({ p: 0, l: e.target.value })}
              rowsPerPage={dataPagination.l}
              labelDisplayedRows={({ _from, _to, count }) => `
              ${initial} -
              ${totalRows > last ? last : totalRows || last} 
               de ${count || '...carregando'}`}
              count={totalRows}
            />,
            Toolbar: CustomToolbar,
            LoadingOverlay: CustomLoadingOverlay
          }
        }


      />
    </div>
  )
}
export default DataTable;
