import React from 'react';
import {Grid} from '@material-ui/core';

function Section({children, sectionLabel}) {
  return (
    <Grid container style={{marginTop: '3em'}} spacing={1}>
    <Grid item container xs={12} sm={4} >
      <h2 style={{padding: '10px'}}>{sectionLabel}</h2>
    </Grid>
    <Grid
     item
     container
     xs={12}
     sm={8}
     direction="column"
     justify="center"
     style={{paddingTop: '20px'}}
     >
    {children}    
    </Grid>
  </Grid>
  )
}

export default React.memo(Section);
