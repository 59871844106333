import * as yup from 'yup'

const emailSchema = yup.object().shape({
  email: yup.string('Ingrese un formato de correo electrónico válido')
    .email('Ingrese un formato de correo electrónico válido')
    .required(' El correo electrónico es un campo obligatorio')
})

export const schoolSchema = yup.object().shape({
  urban_id: yup.number().required('urban'),
  public_id: yup.number().required('pub'),
  neighborhood_id: yup.number().required('neighborhood'),
  authorized_id: yup.string().required('authorized'),
  cost_id: yup.number().required('cost'),
  genre_id: yup.number().required('genre'),
  phone1: yup.number().required('phone'),
  email1: yup.string().required('email'),
  
  address: yup.string().required('address'),
  latitude: yup.number().required('lat'),
  longitude: yup.string().required('long'),
  active: yup.bool().required('active'),
  completed: yup.bool().required('completed') ,
  language1_id: yup.number().required('language1') ,
})

export default emailSchema