import React from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem'
import Section from '../../../../assets/themeComponents/components/Section'
import TextField from '@material-ui/core/TextField'

const Description = React.memo(({ description, styles, setDescription }) => {
  return (
    <Section sectionLabel="Descripción">
      <SectionItem itemLabel="Descripción institución">
        <TextField
          id="outlined-multiline-static"
          value={description}
          className={styles.textField}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={7}
          variant="filled"
        />
      </SectionItem>
    </Section>
  )
})

export default Description;
