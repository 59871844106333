import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { PrivateRoute, LockedRoute } from './Route';
import Login from '../pages/Login';
import Create from '../pages/Create';
import Register from '../pages/Register';
import Ambassador from '../pages/Ambassador';
import Dashboard from '../pages/Dashboard';
import Admin_Edit from '../pages/Edit';
import PasswordChange from '../pages/Password';
import UserProvider from '../contexts/UserProvider';
import DashBoardProvider from '../contexts/DashBoardProvider';
// import ChangeTest from '../pages/Ambassador/ChangeTest';

const Routes = () => (
  <Switch>
    <LockedRoute path="/" exact component={Login} />
    <Route path="/register" component={Register} />
    <PrivateRoute path="/create" component={Create} />
    <PrivateRoute path="/ambassador_input/:school+" component={Ambassador} />
    <PrivateRoute path="/admin_edit/:school+" component={Admin_Edit} />
    {/*<PrivateRoute path="/admin_edit/:school+" component={AdminDashBoard} /> */}
    {/* <PrivateRoute path="/teste/:school+" component={ChangeTest} /> */}

    <PrivateRoute path="/dashboard" >
      <DashBoardProvider>
        <UserProvider >
          <Dashboard />
        </UserProvider>
      </DashBoardProvider>
    </PrivateRoute>
    <PrivateRoute path="/change-password" component={PasswordChange} />
  </Switch>
);

export default Routes;
