import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core';
import Logo from "../../assets/Vacanted_LOGO_header.png";
import api from "../../services/api";

function Register() {
  const [loginData, setLoginData] = useState({ name: '', email: '', password: '', checkPassword: '' });
  const [passwordError, setPasswordError] = useState({ msg: 'Las Contraseñas no coinciden', error: false });
  const loginRef = useRef(null);
  const history = useHistory();

  useEffect(() => loginRef.current.focus(), []);

  const handleInputs = ({ target }) => {
    const { name, value } = target;
    setLoginData({ ...loginData, [name]: value });
    setPasswordError({ ...passwordError, error: false });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const { name, email, password, checkPassword } = loginData;

    if (String(checkPassword) !== String(password)) {
      setPasswordError({ ...passwordError, error: true });

      return;
    }

    const data = {
      name,
      email,
      password,
      role_id: 3,
    };

    await api
      .post("user", data)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
    
    setTimeout(() => history.push('/'), 1000);
  }

  const renderCheckPasswordError = () => (
    <div className="check-password-error-container">
      <p className="warning-p"><strong>{passwordError.msg}</strong></p>
    </div>
  );
    
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "90vh" }}
    >
      <form onSubmit={handleSubmit} className="login-register-form">
        <img src={Logo} alt="Login" />
        <div className="input-fields">
          <input
            type="text"
            placeholder="Nombre"
            name="name"
            onChange={handleInputs}
            required
            ref={loginRef}
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={handleInputs}
            required
          />
          <input
            type="password"
            placeholder="Clave"
            name="password"
            onChange={handleInputs}
            required
          />
          <input
            type="password"
            placeholder="Repita la Clave"
            name="checkPassword"
            onChange={handleInputs}
            required
          />
          {passwordError.error && renderCheckPasswordError()}
        </div>
        <button type="submit">REGISTRAR</button>
      </form>
    </Grid>
  );
}

export default Register;
