import React, { createContext, useCallback, useContext, useState } from "react";
import api from "../services/api";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@VacantedForm:token");
    const user = localStorage.getItem("@VacantedForm:user");

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {};
  });
  const signIn = useCallback(async (data, addError) => {
    try {
      const response = await api.post("login", {
        email: data.email,
        password: data.password,
      });
      addError('')

      const { token, user } = response.data;

      localStorage.setItem("@VacantedForm:token", token);
      localStorage.setItem("@VacantedForm:user", JSON.stringify(user));

      setData({ token, user });
    } catch (e) {
      addError('Nombre de usuario o contraseña incorrectos')
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@VacantedForm:token");
    localStorage.removeItem("@VacantedForm:user");

    setData({});
  }, []);
  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, token: data.token }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
