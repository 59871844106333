import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";
// import Login from "./pages/Login";
import { AuthProvider } from "./hooks/AuthContext";
import theme from './assets/themeComponents/components/themeContext/ThemeContext'
import { ThemeProvider } from '@material-ui/core/styles'

import './assets/styles.css'
import UserProvider from "./contexts/UserProvider";
import DashBoardProvider from "./contexts/DashBoardProvider";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Routes />
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
