import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";
import Logo from "../../assets/Vacanted_LOGO_header.png";
import { useAuth } from "../../hooks/AuthContext";
import { Grid } from "@material-ui/core";
import "./login.modules.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const { signIn } = useAuth();
  const emailRef = useRef(null);

  useEffect(() => emailRef.current.focus(), []);

  async function handleLogin(event) {
    event.preventDefault();
    const data = {
      email,
      password,
    };

    const response = await signIn(data, setLoginError);
    console.log(response);
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "90vh" }}
    >
      <form onSubmit={handleLogin} className="login-register-form">
        <img src={Logo} alt="Login" />
        <div className="input-fields">
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
            ref={emailRef}
          />
          <input
            type="password"
            placeholder="Contraseña"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {loginError !== "" && 
          <p className="warning-p" style={{
            width: '100%',
            textAlign: 'center'
          }}>{loginError}</p>}
          <button type="submit">INGRESAR</button>
        </div>
        {/* <Link to="/register">
          <strong>Registrar</strong>
        </Link> */}
      </form>
    </Grid>
  );
}

export default Login;
