import React from 'react';
import Button from '@material-ui/core/Button';
import SwitchBox from '../../../../assets/themeComponents/components/SwitchBox';
import { makeStyles } from '@material-ui/core/styles';
import MultiSelectForm from '../../../../assets/themeComponents/components/MultiSelectForm';

const styles = {
  closeButton: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 20,
    padding: '14px',
    minWidth: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    background: '#0096ed',
    marginLeft: '10px',
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 20,
    padding: '14px',
    minWidth: 20,
  },
  languageContainer: {
    marginBottom: '20px',
  },
};

const useStyles = makeStyles(styles);

const ForeignLanguages = (
  ({ foreign, languagesList, otherUsedLanguages }) => {
    const classes = useStyles();
    const {
      enableForeignLanguage,
      foreignLanguages,
      setForeignLanguages,
      setEnableForeignLanguages,
    } = foreign;
    const { primary, secundary, tertiary } = otherUsedLanguages;
    return (
      <>
        <h4 htmlFor='foreignLanguage'>
          Lengua extranjera
          <Button
            color='primary'
            variant='contained'
            className={classes.addButton}
            type='button'
            disabled={!enableForeignLanguage}
            onClick={(e) =>
              setForeignLanguages([
                ...foreignLanguages,
                {
                  name: `foreign-lng${foreignLanguages.length}`,
                  id: languagesList.filter(lng => foreignLanguages
                    .reduce((bool,fl) => bool && lng.id !== fl.id, true))[0].id,
                },
              ])
            }
          >
            +
          </Button>
        </h4>
        <SwitchBox
          name='foreignLanguages'
          enableLabel='Si'
          disableLabel='No'
          setValue={setEnableForeignLanguages}
          value={enableForeignLanguage}
        />
        {foreignLanguages?.length > 0 &&
          enableForeignLanguage &&
          foreignLanguages.map((f_lng, index) => {
            return (
              <div
                className={classes.languageContainer}
                key={f_lng.name + index}
              >
                <div className={classes.buttonContainer}>
                  <Button
                    classes={{ root: classes.closeButton }}
                    variant='contained'
                    onClick={() => 
                      setForeignLanguages(
                        foreignLanguages.filter((lng, i) => i !== index)
                      )
                    }
                  >
                    x
                  </Button>
                </div>
                <MultiSelectForm
                  model={`foreign-lng${index}`}
                  options={languagesList.filter(
                    (lng) =>
                      lng.id !== primary &&
                      lng.id !== secundary &&
                      lng.id !== tertiary &&
                      foreignLanguages.reduce((boolResult, fl, i) => {
                        if (lng.id !== fl.id && i > 7) {
                          return boolResult && false  
                        }
                        return boolResult && true
                        
                      }, true)
                  )}
                  index={index}
                  list={foreignLanguages}
                  optionDetails={{ id: 'id', name: 'name' }}
                  setValue={setForeignLanguages}
                />
              </div>
            );
          })}
      </>
    );
  }
);

export default ForeignLanguages;
