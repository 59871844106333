import React from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem'
import Section from '../../../../assets/themeComponents/components/Section'
import InputText from '../../../../assets/themeComponents/components/InputText';

function ContactInfo({ email, website, phone}) {
  return (

    <Section sectionLabel="Información de contacto">
    <SectionItem itemLabel="Teléfono 1 Postulaciones *">
      <InputText
        type="tel"
        label=""
        id="phone"
        setValue={phone.setPhoneNumber}
        value={phone.phoneNumber}
        />
    </SectionItem>
    <SectionItem itemLabel="Sitio web">
      <InputText
        type="url"
        id="website"
        setValue={website.setWebsite}
        value={website.website}
        />
    </SectionItem>
    <SectionItem itemLabel="E-mail 1 Postulaciones *">
      <InputText
        type="email"
        setValue={email.setEmailCom}
        onBlur={(e) => email.handleBlur(e)}
        value={email.emailCom}
        error={email.emailError !== ''}
        required={true}
        />
    </SectionItem>
    
  </Section >
  );
}

export default ContactInfo;
