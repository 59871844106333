import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { select } from '../../../pages/Ambassador/index.module.css';
// const changeForeignLanguages = (lngs, value, index, options, updater) => {
//   console.log(lngs,value, index, ‘usecallback ’)
//   updater(replaceValueInList(lngs, value, index, options))}
const changeForeignLanguages = (flngs, value, name, index, updater) => {
  console.log(flngs);
  const newLanguages = [...flngs];
  newLanguages[index] = { name, id: value };

  updater(newLanguages);
};

const MultiSelectForm = React.memo((props) => {
  const {
    model,
    classes,
    options,
    setValue,
    disabled,
    optionDetails,
    list,
    index,
  } = props;

  const isLanguageUsed = (option, list, index) => {
    const usedOption = list.find((lng, i) => {
        if (lng.id === option.id && index !== i) {
          return true;
        }
        return false;
    });
    if (usedOption) return true;
    return false;
  };

  return (
    <FormControl variant='outlined'>
      <Select
        classes={{ ...classes }}
        inputProps={{ className: select }}
        variant='filled'
        labelId={model}
        label={model}
        name={model}
        id={model}
        value={list[index].id}
        onChange={(e) =>
          changeForeignLanguages(
            list,
            e.target.value,
            e.target.name,
            index,
            setValue
          )
        }
        disabled={disabled}
      >
        {options.map((option) => {
          if (isLanguageUsed(option, list, index)) {
            return null;
          }
          return (
            <MenuItem
              key={option[optionDetails.name]}
              value={option[optionDetails.id]}
            >
              <em>
                {' '}
                {!option[optionDetails.name] ||
                option[optionDetails.name] === ''
                  ? 'Seleccione una opción'
                  : option[optionDetails.name]}
              </em>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});

export default MultiSelectForm;
