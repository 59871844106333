import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import Logo from '../../assets/vacanted.png';
import emailSchema, { schoolSchema } from '../../services/validations'
import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';
import { Grid, Button } from '@material-ui/core'
import styles from './index.module.css';
import '../../assets/index.css'
import GeneralInfo from './sections/GeneralInfo';
import AddressData from './sections/AddressData'
import Description from './sections/Description';
import ContactInfo from './sections/ContactInfo';
import MoreData from './sections/MoreData';
import Schedules from './sections/Schedules';
import Languages from './sections/Languages';
import AditionalInfo from './sections/AditionalInfo';
import mapSchedule from '../../utils/mapSchedule'

function Ambassador() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const history = useHistory();
  const [mapData, setMapData] = useState({})
  const [mapInfo, setMapInfo] = useState({})
  const [primaryLanguage, setPrimaryLanguage] = useState('')
  const [secundaryLanguage, setSecundaryLanguage] = useState('')
  const [enableSecundaryLanguage, setEnableSecundaryLanguage] = useState(false)
  const [tertiaryLanguage, setTertiaryLanguage] = useState('')
  const [enableTertiaryLanguage, setEnableTertiaryLanguage] = useState(false)
  const [enableForeignLanguage, setEnableForeignLanguages] = useState(false)
  const [localities, setLocalities] = useState([]);
  const [religions, setReligion] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [publics, setPublics] = useState([]);
  const [genres, setGenres] = useState([]);
  const [authorizations, setAuthorizations] = useState([]);
  const [costs, setCosts] = useState([]);
  const [rangePrices, setRangePrices] = useState([]);
  const [chosenStates, setChosenState] = useState('');
  const [chosenUrban, setChosenUrban] = useState('');
  const [countryId, setCountryId] = useState('')
  const [chosenReligion, setChosenReligion] = useState('');
  const [chosenCity, setChosenCity] = useState('');
  const [address, setAddress] = useState('');
  const [chosenNeighborhood, setChosenNeighborhood] = useState('');
  const [specialNeeds, setSpecialNeeds] = useState(false);
  const [chosenModel, setChosenModel] = useState('');
  const [religious, setReligious] = useState('');
  const [emailCom, setEmailCom] = useState('');
  const [email2, setEmail2] = useState('');
  const [email3, setEmail3] = useState('');
  const [website, setWebsite] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phone2, setPhoneNumber2] = useState('');
  const [phone3, setPhoneNumber3] = useState('');
  const [phone4, setPhoneNumber4] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [chosenGenre, setChosenGenre] = useState('');
  const [chosenRangePrice, setChosenRangePrice] = useState(null);
  const [chosenAuthorization, setChosenAuthorization] = useState('');
  const [chosenCost, setChosenCost] = useState('');
  const [idVacanted, setIdVacanted] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [foreignLanguages, setForeignLanguages] = useState([])
  const [updateSchedules, setUpdateSchedules] = useState(false);
  const [schoolError, setSchoolError] = useState({ active: false, message: '' })
  const [imageError, setImageError] = useState({ active: false, message: '' })
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState(null)
  const [isContactDisabled, setIsContactDisabled] = useState()
  const { params } = useRouteMatch();
  const { user, token } = useAuth();
  const { school: schoolId } = params;

  useEffect(() => {
    const nullModelField = 1
    if (!enableSecundaryLanguage) {
      if (enableTertiaryLanguage) {

        setSecundaryLanguage(nullModelField)
        setTertiaryLanguage(nullModelField)
        setEnableTertiaryLanguage(false)
      } else {
        setSecundaryLanguage(nullModelField)
      }
    } 

  }, [enableSecundaryLanguage, setEnableTertiaryLanguage]);

  useEffect(() => {
    if (!enableForeignLanguage) {
      setForeignLanguages([])
    } else {
      if (languagesList.length > 0) {
        setForeignLanguages([
          { 
            name : `foreign-lng${0}`,
            id: 1,
          }
            
          ])

      }
    }
  }, [enableForeignLanguage])

  useEffect(() => {
    async function fill() {
      const { data } = await api.get(`/school/${schoolId}`);
      setMapData({
        country: data.country.name,
        city: data.city.name,
        address: data.address,
        name: data.name
      })
      setName(data.name);
      setDescription(data.description);
      setIdVacanted(data.id_vacanted);
      setChosenState(data.state_id);
      setChosenCity(data.city_id);
      setCountryId(data.country_id);
      setPreviewImages([data.logo]);
      setChosenNeighborhood(data.neighborhood_id);
      setAddress(data.address);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      setChosenUrban(data.urban_id);
      setEmailCom(data.email1);
      setEmail2(data.email2);
      setEmail3(data.email3);
      setPhoneNumber(data.phone1);
      setPhoneNumber2(data.phone2);
      setPhoneNumber3(data.phone3);
      setPhoneNumber4(data.phone4);
      setWebsite(data.website);
      setChosenCost(data.cost_id);
      setChosenRangePrice(data.range_price_id);
      setChosenGenre(data.genre_id);
      setReligious(data.religious);
      setChosenReligion(data.religion_id);
      setSpecialNeeds(data.special_needs);
      setChosenAuthorization(data.authorized_id);
      setChosenModel(data.public_id);
      setPrimaryLanguage(data.language1_id)
      setSecundaryLanguage(data.language2_id)
      setTertiaryLanguage(data.language3_id)

      if (data.languages.length > 0) {
        setEnableForeignLanguages(true)
        const dbLanguages = data.languages.map((lng, index) => ({
          name : `foreign-lng${index}`,
          id: lng.language_id,
        }))
        setForeignLanguages(dbLanguages)
      }

      if (data.language2_id !== 1) {
        setEnableSecundaryLanguage(true)
      }
      if (data.language3_id !== 1) {
        setEnableTertiaryLanguage(true)
      }

      setUpdateSchedules(true);
      setSchedules(data.schedules);
      
      setIsActive(data.active);
      setIsContactDisabled(data.contact_disabled);

      const genres = await api.get(`genre/${data.country_id}`);
      setGenres(genres.data);

      const language = await api.get(`language/${data.country_id}`);
      setLanguagesList(language.data);

      const publi = await api.get(`/public/${data.country_id}`);
      setPublics(publi.data);

      const cost = await api.get(`/cost/${data.country_id}`);
      setCosts(cost.data);

      const rangePricesForCountry = await api.get(`/rangeprice/${data.country_id}/schools`, {
        headers: {
          authorization: token
        }
      });
      setRangePrices(rangePricesForCountry.data);

      const religion = await api.get(`religion/${data.country_id}`);
      setReligion(religion.data);

      const authorization = await api.get(`/authorized/${data.country_id}`);
      setAuthorizations(authorization.data);

      const urban = await api.get(`urban/${data.country_id}`);
      setLocalities(urban.data);

      const response = await api.get(`/state/${data.country_id}`);
      setState(response.data);
      setLoading(false)
    }
    fill();
  }, []);


  useEffect(() => {
    async function statesChange() {
      const cities = await api.get(`/city/state/${countryId}/${chosenStates}`);
      setCities(cities.data);
    }
    if (chosenStates !== '') statesChange();
  }, [chosenStates, countryId]);

  useEffect(() => {
    async function citiesChange() {
      const neighborhoods = await api.get(
        `/neighborhood/${countryId}/${chosenCity}`
      );
      setNeighborhoods(neighborhoods.data);
    }

    if (chosenCity !== '' && chosenStates !== '' && countryId !== '') citiesChange();
  }, [chosenCity, chosenStates, countryId]);

  useEffect(() => {
    if (religious === true && chosenReligion === "") setChosenReligion(1)
  }, []);

  async function handleSubmitCompleted(event) {
    event.preventDefault();
    const updated_schedules = mapSchedule(schedules, updateSchedules, schoolId)

    const data = {
      name,
      description,
      urban_id: chosenUrban,
      public_id: chosenModel,
      religion_id: chosenReligion,
      neighborhood_id: chosenNeighborhood,
      range_price_id: chosenRangePrice,
      authorized_id: chosenAuthorization,
      cost_id: chosenCost,
      genre_id: chosenGenre,
      phone1: phoneNumber,
      phone2,
      phone3,
      phone4,
      email1: emailCom,
      email2,
      email3,
      website,
      religious,
      address,
      ...mapInfo,
      latitude,
      longitude,
      active: isActive,
      completed: true,
      contact_disabled: isContactDisabled,
      schedules: updated_schedules,
      languages: foreignLanguages.map(fl => ({
        language_id: fl.id,
        language: languagesList.find(lng => lng.id === fl.id).name
      })),
      language1_id: primaryLanguage,
      language2_id: secundaryLanguage,
      language3_id: tertiaryLanguage
    };


    try {
      await schoolSchema.validate(data)
      setSchoolError({ message: '', active: false })
      await api.updateSchool(data, images, schoolId, token)
        .then(response => {
          history.push('/dashboard')
        })
        .catch(error => {
          console.warn(error)
        })

    } catch (err) {
      console.log(err)
      setSchoolError({ message: 'Hay campos obligatorios sin llenar', active: true })
    }
  }

  async function handleClick(e) {
    const updated_schedules = mapSchedule(schedules, updateSchedules, schoolId)

    const data = {
      name,
      description,
      urban_id: chosenUrban,
      public_id: chosenModel,
      religion_id: chosenReligion,
      neighborhood_id: chosenNeighborhood,
      range_price_id: chosenRangePrice,
      authorized_id: chosenAuthorization,
      cost_id: chosenCost,
      genre_id: chosenGenre,
      phone1: phoneNumber,
      phone2,
      phone3,
      phone4,
      email1: emailCom,
      email2,
      email3,
      website,
      religious,
      address,
      ...mapInfo,
      latitude,
      longitude,
      active: isActive,
      completed: false,
      contact_disabled: isContactDisabled,
      schedules: updated_schedules,
      languages: foreignLanguages.map((fl, index) => ({
        language_id: fl.id,
        language: languagesList.find(lng => lng.id === fl.id).name,
        id: index
      })),
      language1_id: primaryLanguage,
      language2_id: secundaryLanguage,
      language3_id: tertiaryLanguage
    };
    await api.updateSchool(data, images, schoolId, token)
      .then(_response => {
        history.push('/dashboard')
      })
      .catch(error => {
        console.log(error)
      })
  }

  function handleSelectImages(event) {
    if (event[0]) {
      setImageError('')
      setImages(event)
      const img = event[0]
      img.src = URL.createObjectURL(img)
      setPreviewImages(img.src)
      return (event, img.src)
      // setPreviewImages(img.src)
    }
  }

async function handleBlur(e) {
  try {
    await emailSchema.validate({ email: e.target.value })
    setEmailError('')
  } catch (err) {
    setEmailError(err.message)
  }
}

if (loading) {
  return <p>...Cargando</p>
}
return (
  <Grid container justify="center">
    <main>
      <form className={styles.form} onSubmit={handleSubmitCompleted}>
        <fieldset>
          <Grid container jusitfy="center" >
            <img alt="school logo" src={Logo} className={styles.logo}></img>
          </Grid>
          <Button>
            <Link to="/dashboard" className="enter-app">
              <FiArrowLeft size={26} color="rgba(0, 0, 0, 0.6" />
            </Link>
          </Button>
          <GeneralInfo
            styles={styles}
            handleSelectImages={handleSelectImages}
            idVacanted={idVacanted}
            setIdVacanted={setIdVacanted}
            name={name}
            setName={setName}
            isActive={isActive}
            setIsActive={setIsActive}
            isContactDisabled={isContactDisabled}
            setIsContactDisabled={setIsContactDisabled}
            selectImage={{
              images,
              previewImages,
              setPreviewImages,
              handleSelectImages,
              imageError,
              setImageError
            }}
          />
          <AddressData
            states={{ states, chosenStates, setChosenState }}
            cities={{ cities, chosenCity, setChosenCity }}
            mapdata={mapData}
            neighborhoods={{ neighborhoods, chosenNeighborhood, setChosenNeighborhood }}
            address={{ address, setAddress }}
            latitude={{ latitude, setLatitude }}
            longitude={{ longitude, setLongitude }}
            localities={{ localities, chosenUrban, setChosenUrban }}
            setMapInfo={setMapInfo}
          />
          <Description
            description={description} styles={styles} setDescription={setDescription}
          />
          <ContactInfo
            styles={styles}
            phone={{ phoneNumber, setPhoneNumber }}
            website={{ setWebsite, website }}
            email={{ emailError, setEmailCom, emailCom, handleBlur }}
          />
          <MoreData
            authorization={{ chosenAuthorization, setChosenAuthorization, authorizations }}
            genre={{ chosenGenre, setChosenGenre, genres }}
            model={{ chosenModel, setChosenModel, publics }}
          />
        </fieldset>

        <fieldset className={user.role_id === 3 ? 'info' : 'disabled'}>
          <Schedules styles={styles} schedules={schedules} setSchedules={setSchedules} />
          <Languages
            primary={{ primaryLanguage, setPrimaryLanguage }}
            secundary={{ secundaryLanguage, setSecundaryLanguage, setEnableSecundaryLanguage, enableSecundaryLanguage }}
            tertiary={{ tertiaryLanguage, setTertiaryLanguage, setEnableTertiaryLanguage, enableTertiaryLanguage }}
            foreign={{ foreignLanguages, styles, user, setForeignLanguages, enableForeignLanguage, setEnableForeignLanguages }}
            languagesList={languagesList}
          />
          <AditionalInfo
            costs={{ costs, chosenCost, setChosenCost }}
            rangePrices={{ rangePrices, chosenRangePrice, setChosenRangePrice }}
            religious={{ religious, setReligious, religions, chosenReligion, setChosenReligion }}
            specialNeeds={{ specialNeeds, setSpecialNeeds }}
          />
        </fieldset>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <label className={styles.alert}>{schoolError.message}</label>
          
          <Button className="confirm-button"
            style={{
              width: '300px',
              height: '40px',
              padding: '0 10px',
              marginTop: '15px',
              color: 'rgba(0,0,0,0.89)'
            }}
            type="button"
            variant="contained"
            onClick={async (e) => handleClick(e)}
            >
            Guardar en Proceso
          </Button>

          <Button className="confirm-button"
            style={{ width: '300px', height: '40px', padding: '0 10px', marginBottom: '28px', marginTop: '15px' }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={emailError !== ""}>
            Confirmar y Enviar
          </Button>
        </div>
      </form>
    </main>
  </Grid>
);
}

export default Ambassador;
