import React from 'react';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {sectionRadioGroup, sectionRadioLabel} from '../../../pages/Ambassador/index.module.css'

export default function InputRadioGroup({groupLabel,value, options, setValue}) {

  return (
    <FormControl component="fieldset" className={sectionRadioGroup}>
      <FormLabel className={sectionRadioLabel} component="legend">{groupLabel}</FormLabel>
      <RadioGroup row aria-label="position" name="position" defaultValue="top">
        {options.map(option => (
          <FormControlLabel
          key={option.name}
          value={option.id}
          onChange={(e) => setValue(e.target.value)}
          checked={String(value) === String(option.id)} 
          control={<Checkbox color="primary"/>}
          label={option.name}
          labelPlacement="start"
          />
          
          )
        )}
        
      </RadioGroup>
    </FormControl>
  );
}