import React, { useState } from 'react';
import DashBoardContext from './DashBoardContext';
import {useAuth} from '../hooks/AuthContext'
import { Redirect } from 'react-router';

function DashBoardProvider({ children }) {
  const [activeSchool, setActiveSchool] = useState(false)
  const {user} = useAuth();
  if (!user) return <Redirect to="/" />
  return (
    <DashBoardContext.Provider value={{ activeSchool, setActiveSchool }}>
      {children}
    </DashBoardContext.Provider>
  )

}

export default DashBoardProvider