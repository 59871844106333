import React, { useContext } from 'react';
import { useAuth } from '../../hooks/AuthContext';
import { useHistory, Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Logo from '../../assets/Vacanted_LOGO_header.png';
import DataTable from '../../components/DataTable';
import UserContext from '../../contexts/UserContext';
import DashBoardContext from '../../contexts/DashBoardContext';

function Dashboard() {
  const { signOut, user } = useAuth()
  const { setDataPagination, dataPagination } = useContext(UserContext)
  const { setActiveSchool, activeSchool } = useContext(DashBoardContext)
  const history = useHistory();

  function handleSignOut() {
    signOut();
  }

  function handleChangePassword() {
    setActiveSchool(true);
    history.push('/change-password');
  }

  function handleCreate() {
    setActiveSchool(true);
    history.push('/create');
  }
  if (!user) return <Redirect to="/" />

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <div className="dashboard-container">
        <div className="dashboard-user-info">
          <div className="logo-container">
            <img src={Logo} alt="vacanted logo" />
          </div>
          <div className="info-container">
            <p><strong>Usuario: </strong>{user.email}</p>
            <button type="button" onClick={handleChangePassword}>
              Cambiar contraseña
            </button>
            <button onClick={handleSignOut} className="signout">
              Salir
            </button>
          </div>
        </div>
        <div className="dashboard-select">
          <button type="button" className={!activeSchool ? 'active' : null}
            onClick={
              () => {
                setDataPagination({ p: 0, l: dataPagination.l })
                setActiveSchool(false)
              }}
          >
            Instituciones en Proceso
          </button>
          <button type="button" className={activeSchool ? 'active' : null}
            onClick={
              () => {
                setActiveSchool(true)
                setDataPagination({ p: 0, l: dataPagination.l })
              }
            }>
            Instituciones completadas
          </button>
          {(user.role_id === 1 || user.role_id === 2) &&
            <button className="create-btn" onClick={handleCreate}>
              Crear
            </button>
          }
        </div>
        <DataTable
        />
      </div>

    </Grid>
  );
}

export default Dashboard;
