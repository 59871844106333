import {Grid} from '@material-ui/core';
import React from 'react';


function SectionItem ({children, itemLabel}) {
  return (
    <Grid container style={{padding: '5px'}}>
    <div>

    <h4>{itemLabel}</h4>
    <div>
      {children}
    </div>
    </div>
  </Grid>
  )
}

export default React.memo(SectionItem);
