import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';
import Logo from '../../assets/vacanted.png';

// import './styles.css';

function Admin_Edit() {
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [chosenNeighborhood, setChosenNeighborhood] = useState('');
  const [chosenStates, setChosenState] = useState('');

  const [chosenCity, setChosenCity] = useState('');
  const [ambassadors, setAmbassadors] = useState([]);
  const [address, setAddress] = useState('');

  const [idVacanted, setIdVacanted] = useState('');
  const [chosenAmbassador, setChosenAmbassador] = useState('');
  const { token } = useAuth();

  const { params } = useRouteMatch();
  const { school } = params;

  useEffect(() => {
    async function fill() {
      const { data } = await api.get(`/school/${school}`);
      console.log(data);
      setName(data.school.name);
      setDescription(data.school.description);
      setChosenNeighborhood(data.school.neighborhood_id);
      setChosenState(data.school.state_id);
      setChosenCity(data.school.city_id);
      setChosenAmbassador(data.school.user_id);
      setIdVacanted(data.school.id_vacanted);
      setAddress(data.school.address);
      const states = await api.get(`/state/2`);
      setState(states.data);

      let config = {
        headers: {
          authorization: token,
        },
      };

      const ambassadors = await api.get('ambassadors', config);
      setAmbassadors(ambassadors.data);
    }
    fill();
  }, [token]);

  useEffect(() => {
    async function statesChange() {
      const cities = await api.get(`/city/2/${chosenStates}`);
      setCities(cities.data);
    }
    statesChange();
  }, [chosenStates]);

  useEffect(() => {
    async function statesChange() {
      const neighborhoods = await api.get(
        `/neighborhood/2/${chosenStates}/${chosenCity}`
      );
      setNeighborhoods(neighborhoods.data);
    }

    statesChange();
  }, [chosenCity, chosenStates]);

  async function handleSubmit(event) {
    const data = {
      country_id: 2,
      state_id: chosenStates,
      city_id: chosenCity,
      name: name,
      address: address,
      id_vacanted: idVacanted,
      description,
      user_id: chosenAmbassador,
    };
    event.preventDefault();
    let config = {
      headers: {
        token: token,
      },
    };
    await api.put(`/schooladmin/${school}`, data, config).then((response) => {
      history.push('/dashboard');
    });

    
  }



  return (
    <div id="page-create-orphanage">
      <main>
        <form className="create-orphanage-form" onSubmit={handleSubmit}>
          <fieldset>
            <legend>
              <img alt="school logo"src={Logo}></img>
            </legend>
            <Link to="/dashboard" className="enter-app">
              <FiArrowLeft size={26} color="rgba(0, 0, 0, 0.6" />
            </Link>

            <div className="input-block">
              <label htmlFor="vacanted_id">Código Vacanted</label>
              <input
                id="name"
                onChange={(e) => setIdVacanted(e.target.value)}
                value={idVacanted}
              />
            </div>

            <div className="input-block">
              <label htmlFor="name">Nombre</label>
              <input
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>

            <div className="input-block">
              <label htmlFor="address">Direcciones</label>
              <input
                id="address"
                placeholder="Dirección de la escuela, Calle, Número y Complemento"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </div>

            <div className="input-block">
              <label htmlFor="states">Provincia</label>
              <select
                id="states"
                onChange={(e) => setChosenState(e.target.value)}
                value={chosenStates}>

                {states.map((state) => {
                  return (
                    <option value={state.id} key={state.id}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-block">
              <label htmlFor="cities">Ciudad</label>
              <select
                id="cities"
                onChange={(e) => setChosenCity(e.target.value)}
                value={chosenCity}>

                {cities.map((city) => {
                  return (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-block">
              <label htmlFor="neighborhood">Barrio</label>
              <select
                value={chosenNeighborhood}
                onChange={(e) => setChosenNeighborhood(e.target.value)}>
                {neighborhoods.map((neighborhood) => {
                  return (
                    <option value={neighborhood.id} key={neighborhood.id}>
                      {neighborhood.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-block">
              <label htmlFor="ambassadors">Embajadores</label>
              <select
                id="ambassadors"
                onChange={(e) => setChosenAmbassador(e.target.value)}
                value={chosenAmbassador}>

                {ambassadors.map((ambassador) => {
                  return (
                    <option value={ambassador.id} key={ambassador.id}>
                      {ambassador.name}
                    </option>
                  );
                })}
              </select>
            </div>   

            <div className="input-block">
              <label htmlFor="about">
                Descripción <span>Maximo 300 caracteres</span>
              </label>
              <textarea
                id="name"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>

            

            
          </fieldset>

          <button className="confirm-button" type="submit">
            Confirmar
          </button>
        </form>
      </main>
    </div>
  );
}

export default Admin_Edit;
