import { createMuiTheme } from '@material-ui/core/styles';


let theme = createMuiTheme({
  
  palette: {
    primary: {
      main: '#0096ed'
    },
    
    secondary: {
      main: 'rgba(255,10,0,0.7)'
    },
    weak: {
      main: '#b9b7b7'
    }
  },
  
})

export default theme;
