import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../hooks/AuthContext';
import UserContext from './UserContext'
import useFetch from 'use-http';
import DashBoardContext from './DashBoardContext';
import { getUrl } from '../urlConfig';

const baseURL = getUrl('api');

const UserProvider = ({ children }) => {
  const { token } = useAuth();
  const options = {
    method: 'GET',
    headers: {
      authorization: token
    },
    cachePolicy: 'NO-CACHE'
  }

  const { activeSchool } = useContext(DashBoardContext)

  const [dataPagination, setDataPagination] = useState({
    p: 0, l: 10
  });

  const [shouldUpdate, setShouldUpdate] = useState(true)

  const { data, error, loading  } = useFetch(
    `${baseURL}/school/user?p=${dataPagination.p}&l=${dataPagination.l}&completed=${activeSchool}`,
    options,
    [shouldUpdate]
  )
  const [fetchError, setFetchError] = useState(error)

  useEffect(() => {
    if (data) {
      setShouldUpdate(false)

    }
  }, [data])

  const isLoading = loading || !data
  useEffect(() => {
    setShouldUpdate(true)
  }, [dataPagination, activeSchool])

  if (!data) return <>...Cargando</>

  return (
    <UserContext.Provider value={{
      schools: data.schools,
      totalRows: data?.rows,
      loading,
      isLoading,
      fetchError,
      setFetchError,
      dataPagination,
      setDataPagination,
      setShouldUpdate
    }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider