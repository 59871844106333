import React from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem';
import Section from '../../../../assets/themeComponents/components/Section';
import SwitchBox from '../../../../assets/themeComponents/components/SwitchBox';
import SelectForm from '../../../../assets/themeComponents/components/SelectForm';
import ForeignLanguages from './ForeignLanguages';

const Languages = React.memo(
  ({ primary, secundary, tertiary, foreign, languagesList }) => {
    return (
      <Section sectionLabel='Lenguas'>
        <SectionItem itemLabel='Primera Lengua *'>
          <SelectForm
            model='is-primary'
            options={languagesList}
            optionDetails={{ id: 'id', name: 'name' }}
            value={primary.primaryLanguage}
            setValue={primary.setPrimaryLanguage}
            disabled={false}
          />
        </SectionItem>
        <SectionItem itemLabel='Bilingüe'>
          <SwitchBox
            name='secundaryLanguage'
            enableLabel='Si'
            disableLabel='No'
            setValue={secundary.setEnableSecundaryLanguage}
            value={secundary.enableSecundaryLanguage}
          />
        </SectionItem>
        <SectionItem style={{ display: 'none' }}>
          {secundary.enableSecundaryLanguage && (
            <SelectForm
              model='is-secundary'
              options={languagesList.filter(
                (lng) => lng.id !== primary.primaryLanguage
              )}
              value={secundary.secundaryLanguage}
              setValue={secundary.setSecundaryLanguage}
              optionDetails={{ id: 'id', name: 'name' }}
              disabled={!secundary.enableSecundaryLanguage}
            />
          )}
        </SectionItem>
        {secundary.enableSecundaryLanguage && (
          <SectionItem itemLabel='Trilingüe'>
            <SwitchBox
              name='tertiaryLanguage'
              enableLabel='Si'
              disableLabel='No'
              setValue={tertiary.setEnableTertiaryLanguage}
              value={tertiary.enableTertiaryLanguage}
            />
          </SectionItem>
        )}
        <SectionItem>
          {secundary.enableSecundaryLanguage &&
            tertiary.enableTertiaryLanguage && (
              <SelectForm
                model='is-tertiary'
                options={languagesList
                  .slice(1)
                  .filter(
                    (lng) =>
                      lng.id !== primary.primaryLanguage &&
                      lng.id !== secundary.secundaryLanguage
                  )}
                value={tertiary.tertiaryLanguage}
                optionDetails={{ id: 'id', name: 'name' }}
                setValue={tertiary.setTertiaryLanguage}
                disabled={!tertiary.enableTertiaryLanguage}
              />
            )}
        </SectionItem>
        <SectionItem>
          <ForeignLanguages
            foreign={foreign}
            languagesList={languagesList}
            otherUsedLanguages={{
              secundary: secundary.secundaryLanguage,
              tertiary: tertiary.tertiaryLanguage,
              primary: primary.primaryLanguage,
            }}
          />
        </SectionItem>
      </Section>
    );
  }
);

export default Languages;
