import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';
import Logo from '../../assets/vacanted.png';

function Create() {
  const history = useHistory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [chosenNeighborhood, setChosenNeighborhood] = useState('')

  const [chosenStates, setChosenState] = useState('');

  const [chosenCity, setChosenCity] = useState('');
  const [ambassadors, setAmbassadors] = useState([]);
  const [address, setAddress] = useState('');

  const [idVacanted, setIdVacanted] = useState('');
  const [chosenAmbassador, setChosenAmbassador] = useState('');
  const { token } = useAuth();

  useEffect(() => {
    async function fill() {
      let config = {
        headers: {
          authorization: token,
        },
      };

      const ambassadors = await api.get('ambassadors', config);
      setAmbassadors(ambassadors.data);

      const states = await api.get('/state/2');
      setState(states.data);
    }
    fill();
  }, [token]);

  useEffect(() => {
    async function statesChange() {
      try {
        const cities = await api.get(`/city/2/${chosenStates}`);
        setCities(cities.data);

      } catch (err) {
        setCities([])
      }

    }
    statesChange();
  }, [chosenStates]);

  useEffect(() => {
    async function statesChange() {
      try {
        const neighborHoods = await api.get(`/neighborhood/2/${
          chosenStates||states[0].id}/${chosenCity||cities[0].id}`
        )
      if (neighborHoods.data.length > 0) {
        setNeighborhoods(neighborHoods.data)

      } else {
        setNeighborhoods([])
      }

      } catch (err) {
        setNeighborhoods([])
      }

    }

    statesChange();
  }, [chosenCity, chosenStates, cities, states]);

  

  async function handleSubmit(event) {
    const data = {
      country_id: 2,
      state_id: chosenStates !== ''   ? chosenStates : states[0].id,
      city_id: chosenCity !== ''  ? chosenCity : cities[0].id,
      neighborhood_id: chosenNeighborhood !== '' ? chosenNeighborhood : neighborhoods[0].id,
      name: name,
      address: address,
      id_vacanted: idVacanted,
      description,
      user_id: chosenAmbassador !== '' ? chosenAmbassador : ambassadors[0].id,
    };
    console.log(ambassadors[0].id, 'first ambassadors id', chosenAmbassador, 'chosen ambassaodr')
    event.preventDefault();
    let config = {
      headers: {
        authorization: token,
      },
    };
    await api.post('schooladmin', data, config).then((response) => {
      console.log(data, response)
      history.push('/dashboard');
    });

    // api
    //   .post("/school", data)
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  

  // function handleSignOut() {
  //   signOut();
  // }

  return (
    <div id="page-create-orphanage">
      <main>
        <form className="create-orphanage-form" onSubmit={handleSubmit}>
          <fieldset>
            <legend>
              <img alt="school logo" src={Logo}></img>
            </legend>
            <Link to="/dashboard" className="enter-app">
              <FiArrowLeft size={26} color="rgba(0, 0, 0, 0.6" />
            </Link>

            

            <div className="input-block">
              <label htmlFor="vacanted_id">Código Vacanted</label>
              <input
                id="name"
                onChange={(e) => setIdVacanted(e.target.value)}
              />
            </div>  

            <div className="input-block">
              <label htmlFor="name">Nombre instituición</label>
              <input id="name" onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="input-block">
              <label htmlFor="address">Direcciones</label>
              <input
                id="address"
                placeholder="Dirección de la escuela, Calle, Número y Complemento"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            
            <div className="input-block">
              <label htmlFor="states">Provincia</label>
              <select
                id="states"
                onChange={(e) => setChosenState(e.target.value)}>

                {states.map((state) => {
                  return (
                    <option value={state.id} key={state.id}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-block">
              <label htmlFor="cities">Ciudad</label>
              <select
                id="cities"
                onChange={(e) => setChosenCity(e.target.value)}>

                {cities.map((city) => {
                  return (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  );
                })}
              </select> 
            </div>

            <div className="input-block">
              <label htmlFor="neighborhood">Barrio</label>
              <select 
                onChange={(e) => setChosenNeighborhood(e.target.value)}              
              >
                {neighborhoods.map((neighborhood) => {
                  return (
                    <option value={neighborhood.id} key={neighborhood.id}>
                      {neighborhood.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-block">
              <label htmlFor="ambassadors">Embajadores</label>
              <select
                id="ambassadors"
                onChange={(e) => setChosenAmbassador(e.target.value)}>

                {ambassadors.map((ambassador) => {
                  return (
                    <option value={ambassador.id} key={ambassador.id}>
                      {ambassador.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-block">
              <label htmlFor="about">
                Descripción  instituición
              </label>
              <textarea
                id="name"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>            

            

            
          </fieldset>

          <button className="confirm-button" type="submit">
            Confirmar
          </button>
        </form>
      </main>
    </div>
  );
}

export default Create;
