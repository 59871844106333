import React from 'react'
import { FormGroup, Grid, Switch} from '@material-ui/core'

function SwitchBox({setValue,disabled, name, enableLabel, disableLabel, value = false}) {
  return (
    <FormGroup>

    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>{disableLabel}</Grid>
      <Grid item>
        <Switch 
          value={value}
          color="primary"
          disabled={disabled}
          checked={value === true}
          onChange={(e) => setValue(e.target.checked)} 
          name={name} />
      </Grid>
      <Grid item>{enableLabel}</Grid>
    </Grid>
    </FormGroup>
    )
}

export default SwitchBox;
