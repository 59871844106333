import { useState, useEffect } from 'react'
import useFetch from 'use-http'

import { useAuth } from './AuthContext'

import { getUrl } from '../urlConfig';

const url = getUrl('api');

export default function useMapData(infoMap) {
  const [enableMap, setEnableMap] = useState(false)
  const [mapData, setMapData] = useState([])
  const [enableGoogleData, setEnableGoogleData] = useState(true)
  const [mapLocation, setMapLocation] = useState("")
  const [mapInfo, setMapInfo] = useState('')
  const [fetchError, setFetchError] = useState('')

  const { token } = useAuth()
  const options = {
    method: 'GET',
    headers: {
      authorization: token
    },
    cachePolicy: 'NO-CACHE'
  }
  const { get, response, data, error, loading } = useFetch(
    `${url}/google/placeid?name=`,
    options,
  )

  const loadMapData = async () => {
    const encoded = (`${infoMap.name} ${infoMap.city} ${infoMap.country}` )
    .replace(/[^A-Za-z0-9_%]/g, ' ')
    // const endpoint = queryString.escape();
    try {
      const googleData = await get(encoded)
      if (!response?.ok || googleData?.name === "Error" || error?.name) throw new Error('err')
      if (response?.ok) setMapData(googleData)
      if (googleData.status === "OK") {
        setFetchError('encontrado')
        
      } else { throw new Error()}
    } catch (err) {
      setFetchError('Institución no encontrada')
    }
  }

  useEffect(() => { if (mapData?.length > 0) setEnableMap(false) }, [mapData])

  useEffect(() => { if (data) setMapData(data.candidates) }, [data])



  useEffect(() => {
    if (enableMap) {
      loadMapData()
      setEnableGoogleData(true)
    } else {
      setEnableMap(false)
      setFetchError('')
    }
  }, [enableMap])

  useEffect(() => {
    if (enableGoogleData === false) {
      setEnableMap(false)
      setFetchError('')
    }
  }, [enableGoogleData])

  useEffect(() => {
    if (mapLocation !== "" && mapData.length > 0) {
      const newMapInfo = mapData.filter(data => data.place_id === mapLocation)
        .map(data => {
          if (mapLocation === 0) {
            return {
              latitude: 0,
              longitude: 0
            }
          }
          return {
            place_id: data.place_id,
            latitude: data.geometry.location.lat,
            longitude: data.geometry.location.lng,
            rating: data.rating,
            user_ratings_total: data.user_ratings_total
          }
        })
      const locationData = newMapInfo[0]
      setMapInfo(locationData)
    }
  }, [mapLocation])

  return {
    loading,
    mapData: mapData,
    fetchError,
    setEnableMap,
    enableGoogleData,
    setEnableGoogleData,
    mapInfo,
    enableMap,
    setMapInfo,
    setMapLocation,
    setMapData
  }
}