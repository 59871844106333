import React from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem'
import Section from '../../../../assets/themeComponents/components/Section'
import InputText from '../../../../assets/themeComponents/components/InputText'
import ImageUploader from 'react-images-upload';
import SwitchBox from '../../../../assets/themeComponents/components/SwitchBox';

const GeneralInfo = React.memo(({ styles, setIdVacanted, idVacanted, name, setName, selectImage, isActive, setIsActive, isContactDisabled, setIsContactDisabled }) => {
  return (
    <Section sectionLabel="Datos iniciales">
      <SectionItem itemLabel="Código Vacanted *">
        <InputText
          label="Id vacanted"
          disabled={true}
          setValue={setIdVacanted}
          value={idVacanted}
          required={true}
          type="text" />
      </SectionItem>
      <SectionItem itemLabel="Nombre institución *" >
        <InputText
          label="Nombre institución"
          setValue={setName}
          value={name}
          className={styles.inputTextField}
        />
      </SectionItem>
      <SectionItem itemLabel="Estados del centro">
        <SwitchBox
          value={!!isActive}
          setValue={setIsActive}
          enableLabel="Centro Activo"
          disableLabel="Centro Inactivo"
        />
        <SwitchBox
          value={!isContactDisabled}
          setValue={(contactEnabled) => setIsContactDisabled(!contactEnabled)}
          enableLabel="Interacciones habilitadas"
          disableLabel="Interacciones deshabilitadas"
        />
      </SectionItem>
      <br />
      <SectionItem itemLabel="Logo">
        
        <ImageUploader
          withIcon={false}
          buttonText='Subir Logo'
          onChange={(e) => selectImage.handleSelectImages(e)}
          defaultImages={[selectImage.previewImages]}
          imgExtension={['.jpg', '.png', '.jpeg', '.gif']}
          maxFileSize={65500}
          singleImage
          label=""
          withLabel={true}
          fileSizeError="Imagen muy grande"
          withPreview
        />
        <p>
          <small>
            Medidas Optimas
           </small>
        </p>
        <p>
          <small>
            Ancho: 256px, Alto: 256px
          </small>
        </p>
        <p>
          <small>
            Formatos Soportados: jpg, png
          </small>
        </p>
        <p>
          <small>
            Peso: 64kb
          </small>
        </p>

      </SectionItem >
    </Section>
  );

})

export default GeneralInfo;

