import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { select } from '../../../pages/Ambassador/index.module.css';

const SelectForm = React.memo((props) => {
  const {
    model,
    classes,
    options,
    setValue,
    value,
    disabled,
    optionDetails,
  } = props;

  return (
    <FormControl variant='outlined'>
      <Select
        classes={{ ...classes }}
        inputProps={{ className: select }}
        variant='filled'
        labelId={model}
        label={model}
        name={model}
        id={model}
        defaultValue={''}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem
            key={option[optionDetails.name]}
            value={option[optionDetails.id]}
          >
            <em>
              {' '}
              {!option[optionDetails.name] || option[optionDetails.name] === ''
                ? 'Seleccione una opción'
                : option[optionDetails.name]}
            </em>
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    //   <div>
    //   <select
    //     id={model}
    //     name={model}
    //     disabled={disabled}
    //     value={value}
    //     onChange={(e) => typeof index === 'string' || typeof index === 'number'
    //     ? setValue(list, e.target.value, index, options, updater)
    //     : setValue(e.target.value)}

    //     >
    //     <option value={1}>Seleccione una opción</option>
    //     {options.map(option => (
    //     <option
    //       key={option[optionDetails.id]}
    //       value={option[optionDetails.id]}
    //       >
    //         {option[optionDetails.name]}</option>))}
    //   </select>
    // </div>
  );
});

export default SelectForm;
