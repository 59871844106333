export const validateNewPassword = ({ newPassword, newPasswordCheck }) => {
  if (newPassword !== newPasswordCheck) return false;

  return true;
}

export const renderCheckPasswordError = (msg) => (
  <div className="check-password-error-container">
    <p className="warning-p"><strong>{msg}</strong></p>
  </div>
);
