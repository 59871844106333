function mapSchedule(schedules, updateSchedules, school) {
  const updated_schedules = []
  if (updateSchedules) {
    schedules.forEach((schedule) => {
      updated_schedules.push({
        ages: schedule.ages,
        step: schedule.step,
        level: schedule.level,
        morning: schedule.morning,
        evening: schedule.evening,
        afternoon: schedule.afternoon,
        double_schedule: schedule.double_schedule,
        time_extension: schedule.time_extension,
        school_id: school,
        id: schedule.id,
      });
    });
  } else {
    schedules.forEach((schedule) => {
      updated_schedules.push({
        ages: schedule.ages,
        step: schedule.step,
        level: schedule.level,
        morning: schedule.morning,
        evening: schedule.evening,
        afternoon: schedule.afternoon,
        double_schedule: schedule.double_schedule,
        time_extension: schedule.time_extension,
        school_id: school,
      });
    });
  }
  return updated_schedules
}

module.exports = mapSchedule;
