const hostWithCountryRegex = /vacanted\.com\.(\w\w)$/i;

const countryCodesToIds = {
  uy: 101,
  ar: 102,
};

const countryCodesToNames = {
  uy: 'Uruguay',
  ar: 'Argentina',
};

const getCurrentCountryCode = () => {
  const result = hostWithCountryRegex.exec(window.location.host?.toLowerCase() || '');
  if (result && result[1]) {
    const countryCode = result[1];
    return countryCode;
  }
  if (process.env.REACT_APP_VACANTED_ENV === 'local') {
    return 'uy';
  }
  throw new Error('No country detected!');
};

const getCurrentCountryId = () => {
  const countryCode = getCurrentCountryCode();
  return countryCodesToIds[countryCode];
};

const getCurrentCountryName = () => {
  const countryCode = getCurrentCountryCode();
  return countryCodesToNames[countryCode];
};

export default {
  getCurrentCountryCode,
  getCurrentCountryId,
  getCurrentCountryName,
};
