import React from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem'
import Section from '../../../../assets/themeComponents/components/Section'
import InputRadioGroup from '../../../../assets/themeComponents/components/InputRadioGroup';


const  MoreData = React.memo(({ genre, model, authorization }) => {
  return (
    <Section sectionLabel="Otras informaciones">
    <SectionItem itemLabel="Autorización *">
      <InputRadioGroup
        value={authorization.chosenAuthorization}
        options={authorization.authorizations.slice(1)}
        setValue={authorization.setChosenAuthorization}
        />
    </SectionItem>
    <SectionItem itemLabel="Género *">
      <InputRadioGroup
        value={genre.chosenGenre}
        groupLabel=""
        options={genre.genres.slice(1)}
        setValue={genre.setChosenGenre}
        />
    </SectionItem>
    <SectionItem itemLabel="Tipo de Gestión *" >
      <InputRadioGroup
        value={model.chosenModel}
        setValue={model.setChosenModel}
        options={model.publics.slice(1)}
        />
    </SectionItem>
  </Section>
  );
})

export default MoreData