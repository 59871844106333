import React, { useEffect } from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem'
import Section from '../../../../assets/themeComponents/components/Section'
import InputText from '../../../../assets/themeComponents/components/InputText'
import SelectForm from '../../../../assets/themeComponents/components/SelectForm'
import InputRadioGroup from '../../../../assets/themeComponents/components/InputRadioGroup'
import { Button } from '@material-ui/core';
import useMapData from '../../../../hooks/use-mapData'
import SwitchBox from '../../../../assets/themeComponents/components/SwitchBox';
// global imports will be implemented in the near future

const AddressData = React.memo(({ states,
  mapdata: infoData,
  cities,
  longitude,
  latitude,
  address,
  neighborhoods,
  setMapInfo,
  localities }) => {
  const {
    mapLocation,
    mapData,
    fetchError,
    enableGoogleData,
    setEnableGoogleData,
    setMapLocation,
    mapInfo,
    enableMap,
    setEnableMap } = useMapData(infoData)

  useEffect(() => {
    if (mapInfo && mapInfo.latitude) {
      latitude.setLatitude(mapInfo.latitude)
      longitude.setLongitude(mapInfo.longitude)
      setMapInfo(mapInfo)
    }
  }, [mapInfo])

  return (
    <Section sectionLabel="Datos de dirección">
      <SectionItem itemLabel="Provincia *">
        <SelectForm
          model="states"
          options={states.states}
          optionDetails={{ id: 'id', name: 'name' }}
          value={states.chosenStates}
          placeholder={states.chosenStates}
          disabled={true}
          setValue={states.setChosenState}
        />
      </SectionItem>
      <SectionItem itemLabel="Ciudad *">
        <SelectForm
          model="cities"
          disabled={true}
          options={cities.cities}
          optionDetails={{ id: 'id', name: 'name' }}
          value={cities.chosenCity}
          setValue={cities.setChosenCity}
        />
      </SectionItem>
      <SectionItem itemLabel="Barrio *">
        <SelectForm
          model="neighborhood"
          options={[{ id: 1, name: '' }, ...neighborhoods.neighborhoods]}
          optionDetails={{ id: 'id', name: 'name' }}
          value={neighborhoods.chosenNeighborhood}
          setValue={neighborhoods.setChosenNeighborhood}
        />
      </SectionItem>
      <SectionItem itemLabel="Dirección *" >
        <InputText
          id="address"
          placeholder="Dirección de la escuela, Calle, Número y Complemento"
          setValue={address.setAddress}
          value={address.address}
          disabled={false}
        />
      </SectionItem>
      <SectionItem >
        {fetchError !== 'encontrado' 
         ? <p className="warning">{fetchError}</p>
          : <p className="sucess">{fetchError}</p>
        
      }
        <Button
          onClick={() => setEnableMap(true)}
          disabled={ fetchError !== '' || enableMap === true || enableGoogleData === false}
          variant="contained"
          color={fetchError !== '' ? "secundary" : "primary"}>
          Buscar la ubicación de la institución
        </Button>
        <div>
          <SwitchBox
            setValue={setEnableGoogleData}
            disabled={enableGoogleData === false}
            value={enableGoogleData}
            enableLabel="activar"
            disableLabel="desactivar"
            checked={enableGoogleData === true}
          />
        </div>

      </SectionItem>

      {mapData && mapData.length > 0 && enableGoogleData &&
        <SectionItem itemLabel="Opciones de localizacion">
          <SelectForm
            model="opcionesGoogle"
            options={mapData}
            value={mapLocation}
            setValue={setMapLocation}
            optionDetails={{ id: "place_id", name: "name" }}
          >
          </SelectForm>
        </SectionItem>}
      <SectionItem itemLabel="Latitud *">
        <InputText
          setValue={latitude.setLatitude}
          value={latitude.latitude}
          disabled={false}
        />
      </SectionItem>
      <SectionItem itemLabel="Longitud *">
        <InputText
          id="long"
          setValue={longitude.setLongitude}
          value={longitude.longitude}
          disabled={false}
        />
      </SectionItem>
      <SectionItem itemLabel="Localización *">
        <InputRadioGroup
          options={localities.localities.slice(1)}
          value={localities.chosenUrban}
          setValue={localities.setChosenUrban}
        />
      </SectionItem>
    </Section>
  )
})

export default AddressData

